import * as API from "@/services/API";

const baseUrl = '/v1/reporting/conversations';
const usageUrl = 'v1/usage-summary'


export default {
    getCall(id) {
        return API.apiClient.get(`${baseUrl}/${id}`);
    },
    getCalls(filters) {
        return API.apiClient.get(baseUrl, {params: filters});
    },
    getCallRecordings(id) {
        return API.apiClient.get(`${baseUrl}/${id}/recordings`);
    },
    getCallData(id) {
        return API.apiClient.get(`${baseUrl}/${id}/data`);
    },
    getCallLog(id) {
        return API.apiClient.get(`${baseUrl}/${id}/log`);
    },
    getCallActions(id) {
        return API.apiClient.get(`${baseUrl}/${id}/actions`);
    },
    getCallTranscript(filters) {
        return API.apiClient.get(`${baseUrl}/${filters.id}/transcript`, {params: { export: filters.export}});
    },  
    requestOutboundCall(payload){
        return API.apiClient.post(`/v1/conversations/instance/${payload.instanceId}/call/${payload.phone}`, {
            ClientId: payload.ClientId
        });
    },
    getQuote(conversationId) {
        return API.apiClient.get(`${baseUrl}/${conversationId}/quotes`);
    },
    getUsage(id) {
        return API.apiClient.get(`${usageUrl}/${id}`);
    }
}